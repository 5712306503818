:root {
  --main-color-lightest: #eff5fb;
  --main-color-lighter: #dbe4f1;
  --main-color-light: #d2e3ff;
  --main-color: #2962ff;
  --main-color-dark: #1565c0;
  --main-color-darker: #0d47a1;
  --background-1: #f6f7f9;
  --background-2: #fafbfc;
  --background-3: #e7eaef;
  --grey-dark: #96a0ab;
  --grey-darker: #384049;
  --white: #fff;
  --black: #000;
  --warning: #ffeb3b;
  --main-text-color: #0245ff;
  --primary-text: #222b35;
  --secondary-text: #5c6b7a;
  --notification-light: #fff2de;
  --notification: #c54400;
  --info: #ccfef3;
  --info-dark: #a9f4e4;
  --progress: #7bf264;
  --progress-darker: #134d13;
  --error-light: #fcd9d7;
  --error: #c7180b;
  --error-dark: #ab000d;
  --active: #0c7d6c;
  --active-dark: #005b4f;
  --partially: #afb42b;
  --partially-dark: #990;
  --picking: #ff7429;
  --picking-dark: #ff5900;
  --draft: #eb004e;
  --draft-dark: #c70042;
  --processing: #4fc3f7;
  --processing-dark: #039be5;
  --placed: #4dd0e1;
  --placed-dark: #00acc1;
  --substituted: #ab41be;
  --substituted-dark: #790e8b;
  --modal-overlay: rgba(26, 56, 81, 0.8);
  --dropshadow: rgba(26, 56, 81, 0.5);
  --background-3: #e7eaef;
  --grey-darker: #384049;
  --grey: #c8d4dd;
  --grey-dark: #96a0ab;
  --primary-text: #222b35;
  --secondary-text: #5c6b7a;
  --active: #0c7d6c;
  --powder-blue: #bbdefb;
  --main-color-lightest: #eff5fb;
  --main-color-lighter: #dbe4f1;
  --main-color-light: #d2e3ff;
  --main-color: #2962ff;
  --main-color-dark: #1565c0;
  --main-color-darker: #0d47a1;
  --main-color-darkest: #0a3e67;
  --background-1: #f6f7f9;
  --background-2: #fafbfc;
  --background-3: #e7eaef;
  --grey: #c8d4dd;
  --grey-dark: #96a0ab;
  --grey-darker: #384049;
  --warning: #ffeb3b;
  --main-text-color: #0245ff;
  --primary-text: #222b35;
  --secondary-text: #5c6b7a;
  --notification-light: #fff2de;
  --notification: #c54400;
  --info: #ccfef3;
  --info-dark: #a9f4e4;
  --progress: #7bf264;
  --progress-darker: #134d13;
  --error-light: #fcd9d7;
  --error: #c7180b;
  --error-dark: #ab000d;
  --active: #0c7d6c;
  --active-dark: #005b4f;
  --partially: #afb42b;
  --partially-dark: #990;
  --picking: #ff7429;
  --picking-dark: #ff5900;
  --draft: #eb004e;
  --draft-dark: #c70042;
  --processing-dark: #039be5;
  --placed: #4dd0e1;
  --placed-dark: #00acc1;
  --substituted: #ab41be;
  --substituted-dark: #790e8b;
  --dropshadow: rgba(26, 56, 81, 0.5);
}
