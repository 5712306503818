$header-height: 60px;
$header-button-icon-height: 32px;
$header-button-icon-width: 32px;

.app-header {
  height: $header-height;
  width: auto;
  background-color: var(--main-color-darkest);
}

.header-icon-button {
  height: 100%;
  padding: 0.5rem;
  img {
    width: $header-button-icon-width;
    height: $header-button-icon-height;
    fill: var(--white);
    object-fit: contain;
  }
  &:hover {
    background-color: #fff;
    img {
      fill: var(--black);
    }
  }
}
